<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<div class="app flex-row align-items-center">
				<div class="container">
					<b-row class="justify-content-center">
						<b-col md="6">
							<div class="clearfix">
								<h1 class="float-left display-3 mr-4">404</h1>
								<h4 class="pt-3">{{ FormMSG(1, "Oops! You're lost") }}.</h4>
								<p class="text-muted">{{ FormMSG(2, 'The page you are looking for was not found') }}.</p>
							</div>
						</b-col>
					</b-row>
					<b-row class="justify-content-center">
						<b-col md="6">
							<b-button variant="success" block @click="redirect">
								<ArrowLeft :size="16" />
								{{ FormMSG(6, 'Go back') }}
							</b-button>
						</b-col>
					</b-row>
				</div>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import { ArrowLeft } from 'lucide-vue';

export default {
	name: 'Page404',

	mixins: [languageMessages],

	components: {
		ArrowLeft
	},

	data() {
		return {
			erreur: {},
			warning: ''
		};
	},

	methods: {
		redirect() {
			this.$router.go(-1);
		}
	}
};
</script>
