var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c("div", { staticClass: "app flex-row align-items-center" }, [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "b-row",
                { staticClass: "justify-content-center" },
                [
                  _c("b-col", { attrs: { md: "6" } }, [
                    _c("div", { staticClass: "clearfix" }, [
                      _c("h1", { staticClass: "float-left display-3 mr-4" }, [
                        _vm._v("404"),
                      ]),
                      _c("h4", { staticClass: "pt-3" }, [
                        _vm._v(
                          _vm._s(_vm.FormMSG(1, "Oops! You're lost")) + "."
                        ),
                      ]),
                      _c("p", { staticClass: "text-muted" }, [
                        _vm._v(
                          _vm._s(
                            _vm.FormMSG(
                              2,
                              "The page you are looking for was not found"
                            )
                          ) + "."
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "justify-content-center" },
                [
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "success", block: "" },
                          on: { click: _vm.redirect },
                        },
                        [
                          _c("ArrowLeft", { attrs: { size: 16 } }),
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(6, "Go back")) +
                              "\n\t\t\t\t\t\t"
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }